import React, { useEffect } from "react";
import cityLogo from "../Images/logo.webp";
import secureLocalStorage from "react-secure-storage";

function UserForm_Header() {
  useEffect(() => {
    secureLocalStorage.setItem("panel", "user");
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm">
          <div className="logo">
            <img src={cityLogo} alt="city-logo.png" className="img-fluid" />
          </div>
        </div>
        <div className="col-sm">
          <p className="logo-text">
            Ashley Cares <br /> Relief Program
          </p>
        </div>
      </div>{" "}
    </div>
  );
}
export default UserForm_Header;
