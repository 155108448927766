import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { signImSchema } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import "./login.css";
import Logo from "../../../Images/logo.webp";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const { type } = useParams();

  useEffect(() => {
    if (
      type !== "osr" &&
      type !== "pavlina" &&
      type !== "accountant" &&
      type !== "customer" &&
      type !== "login"
    ) {
      navigate("/admin/login");
    }

    secureLocalStorage.setItem("panel", "admin");
    // eslint-disable-next-line
  }, [type]);

  const loginCheck = async (values) => {
    setLoading(true);
    try {
      if (type === "customer") {
        const response = await AdminListService.loginCustomer(values);

        if (response.status === 200) {
          await userActions.logIn(
            {
              customerAccessToken: response.data.data.access_token,
              customer_id: response.data.data.AdminId,
              customerSiteId: response.data.data.SiteId,
            },
            "customerLogin"
          );
          navigate("/3in1Step", { replace: true });
          setLoading(false);
        }
      } else if (type === "osr") {
        const response = await AdminListService.loginOsr(values);

        if (response.status === 200) {
          await userActions.logIn(
            {
              osrAccessToken: response.data.data.access_token,
              osr_id: response.data.data.OsrId,
            },
            "osrLogin"
          );

          navigate("/osr_dashboard", { replace: true });
          setLoading(false);
        }
      } else if (type === "pavlina") {
        const response = await AdminListService.loginPavlina(values);

        if (response.status === 200) {
          await userActions.logIn(
            {
              pavlinaAccessToken: response.data.data.access_token,
              pavlina_id: response.data.data.pavlinaId,
            },
            "pavlinaLogin"
          );
          navigate("/searchcerti_pav", { replace: true });

          setLoading(false);
        }
      } else if (type === "accountant") {
        const response = await AdminListService.loginAccountant(values);

        if (response.status === 200) {
          await userActions.logIn(
            {
              accountantAccessToken: response.data.data.access_token,
              accountant_id: response.data.data.AccId,
            },
            "accountantLogin"
          );
          navigate("/account_dashboard", { replace: true });
          setLoading(false);
        }
      } else if (type === "login") {
        const response = await AdminListService.loginAdmin(values);
        if (response.status === 200) {
          await userActions.logIn(
            {
              adminAccessToken: response.data.data.access_token,
              admin_id: response.data.data.AdminId,
            },
            "adminLogin"
          );
          navigate("/admin_dashboard", { replace: true });
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);

      if (err?.response?.status === 401) {
        toast.error("Username or Password is invalid!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: signImSchema,
      onSubmit: (values, action) => {
        loginCheck(values);
      },
    });
  return (
    <div className="login-main">
      <Helmet>
        {type === "customer" ? (
          <title> {process.env.REACT_APP_SITE_NAME} Customers Login</title>
        ) : type === "osr" ? (
          <title> {process.env.REACT_APP_SITE_NAME} OSR Login</title>
        ) : type === "accountant" ? (
          <title> {process.env.REACT_APP_SITE_NAME} Accountant Login</title>
        ) : type === "pavlina" ? (
          <title> {process.env.REACT_APP_SITE_NAME} Pavlina Login</title>
        ) : type === "login" ? (
          <title>Admin Panel For CoreAdmin</title>
        ) : (
          <title>Admin Panel For CoreAdmin</title>
        )}
      </Helmet>

      <div className="container">
        <div className="box-outer">
          <div className="login-box">
            <div className="mws-login-lock">
              <i className="bi bi-lock-fill"></i>
            </div>
            <div className="col-lg-12 login-key">
              <img src={Logo} alt="" />
            </div>

            <div className="col-lg-12 login-form">
              <div className="col-lg-12 login-form">
                {(errors.username && touched.username) ||
                (errors.password && touched.password) ? (
                  <div className="alert border-0 alert-danger alert-dismissible fade show  py-2">
                    <div className="d-flex align-items-center">
                      <div className="text-danger">
                        Username or Password is required!
                      </div>
                    </div>
                  </div>
                ) : null}

                <form onSubmit={handleSubmit} noValidate>
                  <div className="form-group">
                    <label className="form-control-label">USERNAME</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="username"
                      value={values.username || ""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">PASSWORD</label>
                    <input
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      value={values.password || ""}
                    />
                  </div>

                  <div className="col-lg-12 loginbttm">
                    <button type="submit" className="btn btn-outline-primary">
                      LOGIN
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
