import React, { useLayoutEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import "./style.css";
import "./Footer.css";
import "./Info_Section.css";
import "./Input_fields.css";
import "./Button.css";
import EmailBox from "./EmailBox";
import SampBox from "./SampBox";
import ContactUs from "./ContactUs";

import HeaderLower from "./HeaderLower";
import Footer from "./Footer";
import GiftBox from "./GiftBox";
import "bootstrap/dist/css/bootstrap.min.css";

import { useNavigate } from "react-router-dom";
import RedemptionService from "../services/redemption.service";

import StorageService from "../services/storage.service";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

function RedemptionForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE;

  const [fields, setFields] = useState({ voucher: "" });
  const [errors, setErrors] = useState({});
  const [i_agree, setI_agree] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const navigate = useNavigate();
  const [checkvoucherfield, setCheckvoucherfield] = useState(
    "The voucher number is required."
  );

  useLayoutEffect(() => {
    const keysToKeep = ["@secure.s.acessTokens", "theme", "@secure.s.panel"]; // Specify the keys you want to keep
    function clearLocalStorageExcept(keysToKeep) {
      for (let key in localStorage) {
        if (!keysToKeep.includes(key)) {
          localStorage.removeItem(key);
        }
      }
    }

    clearLocalStorageExcept(keysToKeep);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((preVal) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const submituserRegistrationForm = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setFields({ voucher: "" });

      let errors = {};
      const checkCount = 0;

      if (checkCount === 0) {
        if (i_agree) {
          var bodyFormData = new FormData();
          bodyFormData.append("certificate", fields.voucher);

          let voucher_two = fields.voucher;

          try {
            const response = await RedemptionService.validateVoucher(
              bodyFormData
            );

            if (response.data.status.includes("true")) {
              secureLocalStorage.setItem("isVoucher", true);
              localStorage.setItem(
                "denomination",
                response.data.data[0].denomination
              );
              // StorageService.setVoucherStatus(true);
              // StorageService.setDenomination(
              //   response.data.data[0].denomination
              // );

              const value = response.data.data[0].redirect;
              const status_value = response.data.status;
              if (status_value.includes("true")) {
                if (value === "userform") {
                  StorageService.setVoucher(voucher_two);
                  localStorage.setItem("voucher", voucher_two);
                  secureLocalStorage.setItem("Redemption", {
                    voucher_two: voucher_two,
                  });
                  window.scrollTo(0, 0);
                  // navigate("/survey-form");
                  navigate("/user-form");
                }
              }
            }

            if (!response.data.status.includes("true")) {
              if (
                !response.data.status.includes("true") &&
                response.data.message.redirect !== undefined &&
                response.data.message.redirect.includes("cert_details")
              ) {
                StorageService.setCertStatus(true);
                const detail_data = response.data.message.data;
                secureLocalStorage.setItem(
                  "cert_details",
                  JSON.stringify({
                    fname: detail_data.first_name,
                    lname: detail_data.last_name,
                    address: detail_data.address,
                    city: detail_data.city,
                    state: detail_data.state,
                    zip: detail_data.zip,
                    phone: detail_data.phone,
                    email: detail_data.email,
                    cardImage: detail_data.cardImage,
                    cardName: detail_data.cardName,
                  })
                );
                navigate("/cert_details");
                // secureLocalStorage.push({
                //   pathname: "/cert_details",
                // });
              } else {
                errors["voucher"] = response.data.message;
                setCheckvoucherfield(response.data.message);

                setErrors(errors);

                return false;
              }
            }
          } catch (err) {
            console.log(err);
            errors["voucher"] = err.response.data.message;
            setErrors(errors);
            console.log(err);

            console.log(err.response.data.message);
          }
        }
      }
    }
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields["voucher"]) {
      formIsValid = false;
      errors["voucher"] = checkvoucherfield;

      setErrors(errors);

      return false;
    }

    if (!i_agree) {
      formIsValid = false;
      errors["checkederror"] = "Select the checkbox";
      setErrors(errors);

      return false;
    }
    if (!captcha) {
      formIsValid = true;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);

      return false;
    }
    return formIsValid;
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="container-fluid">
        <HeaderLower />
        <div className="white-box ">
          <h1>{process.env.REACT_APP_REMPTIONTITLE}</h1>
          <form
            method="post"
            name="userRegistrationForm"
            className="form-outer"
            onSubmit={submituserRegistrationForm}
          >
            <ul className="points">
              <li>
                Enter your voucher number below<br></br>
                <strong>
                  Please Note: Voucher numbers are Case Sensitive with no
                  spaces.
                </strong>
              </li>
              <li>
                <div className="input-outer1">
                  <input
                    type="text"
                    maxLength="11"
                    placeholder="Enter Voucher Number"
                    name="voucher"
                    className="searchBox_deals vc-validate"
                    value={fields.voucher || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.voucher}</div>
                </div>
              </li>
              <li>
                <EmailBox />
              </li>
              <li key="uniqueId4">
                <SampBox />
              </li>
              <li>
                <GiftBox />
              </li>
              <li>
                <ContactUs />
              </li>
              <li className="removing" key="uniqueId6">
                <label>
                  <input
                    type="checkbox"
                    id="myCheck"
                    name="test"
                    className="conditions"
                    value={fields.checkbox}
                    onChange={handleChangecheckbox}
                  />
                  <span> YES,I HAVE READ THE ABOVE INFORMATION</span>
                  <div className="errorMsg">{errors.checkederror}</div>
                </label>
                <br></br>
                <div className="overflow-hider">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    onChange={onCaptchaChange}
                  />
                </div>
                <div className="errorMsg">{errors.checkederrorcaptcha}</div>
                <input
                  // onClick={() => myTimeout()}
                  type="submit"
                  name="submit"
                  id="submit"
                  value="SUBMIT"
                  className="sub-btn button"
                ></input>
              </li>
            </ul>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default RedemptionForm;
